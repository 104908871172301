<template>
  <v-select
    class="status-max-width"
    :items="statuses"
    item-text="text"
    item-value="value"
    return-object
    v-model="selectedStatus"
    @change="$emit('onChangeStatus', selectedStatus)"
  >
    <template v-slot:selection="{ item }">
      <span :class="item.color">
        {{ item.text }}
      </span>
    </template>
    <template v-slot:item="{ item }">
      <span :class="item.color">
        {{ item.text }}
      </span>
    </template>
  </v-select>
</template>

<script>
import { DRP_RESCUE_STATUSES } from "../../../../../utils/utils";

export default {
  name: "RescueStatusSelect",

  props: {
    pStatus: Object,
  },

  watch: {
    pStatus() {
      this.selectedStatus = this.pStatus;
    },
  },

  data() {
    return {
      selectedStatus: this.pStatus,
    };
  },

  computed: {
    statuses() {
      return Object.values(DRP_RESCUE_STATUSES);
    },
  },
};
</script>

<style lang="scss">
.status-max-width {
  max-width: 150px;
  .v-input__slot {
    margin: 0 !important;
  }
}
</style>
